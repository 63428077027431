import shopifyImpactDefaults from '../shopify-impact-generic/config.js';

const wheelsFields = shopifyImpactDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyImpactDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

const isWinterPackagePage = window.location.pathname === '/pages/winter-package';
const isECUTuningPage = window.location.pathname === '/pages/ecu-tuning';

const widgets = shopifyImpactDefaults.Widgets.map((widget) => {
  const { name, type } = widget;
  if (type === 'FacetPanel' || name === 'FacetDialog') {
    const ignoreFields = widget.ignoreFields || [];
    widget.ignoreFields =
      isWinterPackagePage || isECUTuningPage
        ? [...ignoreFields, 'category', 'vendor', 'Fitment']
        : ignoreFields;
  }
  if (
    name !== 'DialogVehicleWidget' &&
    name !== 'VehicleSpecs' &&
    (type === 'VehicleTabs' || type === 'VehicleWidget' || type === 'VehicleTiles')
  ) {
    widget.visibleIf = () => !isWinterPackagePage && !isECUTuningPage;
  }

  return widget;
});

function getLocalPreselection(pageType, defaults) {
  if (isWinterPackagePage) {
    return [{ field: 'category', term: 'Winter Package' }];
  }
  if (isECUTuningPage) {
    return [{ field: 'category', term: 'ECU Tuning' }];
  }
  return defaults.getter(pageType);
}

export default {
  includes: ['shopify-impact-generic'],
  ...shopifyImpactDefaults,
  getLocalPreselection,
  SearchRequestDefaults: {
    ...shopifyImpactDefaults.SearchRequestDefaults,
    extra: {
      ...shopifyImpactDefaults.SearchRequestDefaults.extra,
      doNotHideWheelsTiresFacets: window.location.pathname === '/',
    },
  },
  Widgets: [
    ...widgets,
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '.cm_tab-content.cm_vehicle-search',
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'HomeWheelBar',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_wheel-search',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: wheelsFields,
    },
    {
      name: 'HomeTireBar',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_tire-search',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: tiresFields,
    },
    {
      name: 'HomeSearchBox',
      type: 'SearchBox',
      location: '.cm_tab-content.cm_keyword-search',
      template: 'SearchBox',
    },
    {
      name: 'TilesSearchPage',
      type: 'SearchPage',
      location: '.cm_facet-panel-tiles',
      template: 'SearchPage',
    },
    {
      name: 'FacetPanelTiles',
      type: 'FacetPanel',
      location: { insertBefore: '.cm_facet-panel-tiles', class: 'cm_vehicle-categories__makes' },
      template: 'FacetPanelMakeTiles',
      disableCollapse: true,
      fields: ['category'],
      showAllAlways: true,
    },
  ],
};
