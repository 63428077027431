
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function FacetPanelMakeTilesRT () {
    return _createElement('div', { 'className': 'cm_make-tiles' }, [this.facets(function () {
            function repeatFacets1(facets, facetsIndex) {
                return [facets(function () {
                        function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                            return [ShowAlwaysValues(function () {
                                    return _createElement('div', { 'className': 'cm_vehicle-categories_category-wrapper' }, _createElement('div', { 'className': 'cm_vehicle-categories_category-container' }, _createElement('a', {
                                        'className': 'cm_vehicle-categories_link',
                                        'href': this.href
                                    }, _createElement('div', { 'className': 'cm_vehicle-categories_category-image-container cm_vehicle-categories_link' }, this.imageUrl ? _createElement('img', {
                                        'className': 'cm_vehicle-categories_category-image',
                                        'src': this.imageUrl + '&width=700&height=700',
                                        'alt': this.value,
                                        'key': '338'
                                    }) : null, !this.imageUrl ? _createElement('div', {
                                        'className': 'cm_vehicle-categories_category-image-title',
                                        'key': '503'
                                    }, '\n                      ', this.value, '\n                    ') : null), _createElement('div', { 'className': 'cm_vehicle-categories_category-title-container cm_vehicle-categories_link' }, _createElement('div', { 'className': 'cm_vehicle-categories_category-title' }, '\n                      ', this.value, '\n                      ', this.isSelected ? _createElement('span', {
                                        'className': 'filter-input_clear',
                                        'key': '906'
                                    }, '\u2715') : null)))));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_vehicle-categories cm_vehicle-categories__flat' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__vehicle-categories' }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cm_vehicle-categories_category-wrapper-repeater  cmRepeater_ShowAlwaysValues' },
                            _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                        ])));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_facets' },
                _map(this.facets, repeatFacets1.bind(this))
            ]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:facets"]